/* eslint-disable @typescript-eslint/explicit-function-return-type */
// LoftShadowDOM.ts
class LoftShadowDOM extends HTMLElement {
  private shadowRootRef: ShadowRoot;

  constructor() {
    super();
    // Attach an open Shadow DOM that fully isolates styles
    this.shadowRootRef = this.attachShadow({ mode: 'open' });
  }

  connectedCallback() {
    this.injectLoftStyles();
    this.injectLoftUtilityClasses();
    this.injectManualStyles();
    this.injectReactContainer();
  }

  private injectLoftStyles() {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = 'https://assets.xometry.com/loft/loft@1.3.2/loft.css';
    this.shadowRootRef.appendChild(link);
  }

  private injectLoftUtilityClasses() {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href =
      'https://assets.xometry.com/loft/loft@1.3.2/utility-classes.css';
    this.shadowRootRef.appendChild(link);
  }

  private injectReactContainer() {
    const container = document.createElement('div');
    container.id = 'loft-shadow-root';
    this.shadowRootRef.appendChild(container);
  }

  private injectManualStyles() {
    const style = document.createElement('style');

    // Paste your entire CSS snippet here as textContent
    style.textContent = ``;

    // Append this <style> to the shadow root
    this.shadowRootRef.appendChild(style);
  }

  public get shadowRootEl(): ShadowRoot {
    return this.shadowRootRef;
  }
}

// Let TypeScript know about <html-loft>
declare global {
  interface HTMLElementTagNameMap {
    'html-loft': LoftShadowDOM;
  }
}

export default LoftShadowDOM;

/* eslint-disable @typescript-eslint/explicit-function-return-type */
// LoftShadowProvider.tsx
import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import "./customElements"; // defines <html-loft>

interface LoftShadowProviderProps {
  children: React.ReactNode;
  sticky?: boolean;
}

/**
 * This provider creates a <html-loft> element
 * and portals children into it.
 */
const LoftShadowProvider = ({
  children,
  sticky = false,
}: LoftShadowProviderProps) => {
  const containerRef = useRef<HTMLSpanElement>(null);
  const [shadowRoot, setShadowRoot] = useState<ShadowRoot | null>(null);

  useEffect(() => {
    const loftEl = document.createElement("html-loft");

    if (containerRef.current) {
      containerRef.current.appendChild(loftEl);
    }

    const root = (loftEl as any).shadowRootEl as ShadowRoot | null;
    if (root) {
      setShadowRoot(root);
    }

    return () => {
      if (containerRef.current && loftEl) {
        containerRef.current.removeChild(loftEl);
      }
      setShadowRoot(null);
    };
  }, []);

  // Not ready yet? No portal
  if (!shadowRoot) {
    return <span ref={containerRef} />;
  }

  // Container for our portal
  const mountPoint = shadowRoot.getElementById("loft-shadow-root");
  if (!mountPoint) {
    return <span ref={containerRef} />;
  }

  // Portal the children into the shadow root
  return (
    <span
      ref={containerRef}
      style={sticky ? { position: "sticky", top: 0, zIndex: 1 } : undefined}
    >
      {ReactDOM.createPortal(children, mountPoint)}
    </span>
  );
};
export default LoftShadowProvider;

/* eslint-disable */
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** ISO Compliant UTC datetime */
  GraphQLDateTime: any;
};

export type CarbonOffsetResponse = {
  readonly __typename?: 'CarbonOffsetResponse';
  /** the total user amount of carbon offset */
  readonly totalUserCo2Pounds: Scalars['Float'];
  /** the total company amount of carbon offset */
  readonly totalWorldCo2Pounds: Scalars['Float'];
};

export type CreatePartArgs = {
  /** The name of the file uploaded for this part by the user */
  readonly fileName: Maybe<Scalars['String']>;
  /** The MD5 checksum for the part file */
  readonly md5: Maybe<Scalars['String']>;
  /** The unique id of the part owner. */
  readonly ownerId: Maybe<Scalars['String']>;
  /** The optional part-file-service id for a file that has already been uploaded. */
  readonly partFileId: Maybe<Scalars['String']>;
};

export type CreatePartResponse = {
  readonly __typename?: 'CreatePartResponse';
  /** the ID for this part */
  readonly id: Maybe<Scalars['String']>;
  /** the user id for the owner of this part */
  readonly ownerId: Maybe<Scalars['String']>;
  /**
   * a flag to indicate that this part already exists. A part already exists if it has the same
   * filename and md5 hash on the same account
   */
  readonly existingPart: Maybe<Scalars['Boolean']>;
  /** the URL you can post the corresponding file to upload. This is only present when it is not a new part */
  readonly uploadUrl: Maybe<Scalars['String']>;
};

export type CreateQuoteArgs = {
  /** An array of the part ids to add to the new quote */
  readonly partIds: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  /** The unique id of the part owner. */
  readonly ownerId: Maybe<Scalars['String']>;
  /** A flag indicating if this is a PunchOut quote */
  readonly isPunchout: Maybe<Scalars['Boolean']>;
};

export type CreateQuoteResponse = {
  readonly __typename?: 'CreateQuoteResponse';
  /** the ID for this quote */
  readonly id: Maybe<Scalars['String']>;
};

/**
 * Each enum in Currency should be defined as <iso-code>_<denomination>, where:
 * - <iso-code> - 3-letter ISO 4217 code referring to a circulating currency, e.g. USD
 * - <denomination> - the smallest unit of currency, e.g. CENTS, PENCE
 */
export enum Currency {
  UsdCents = 'USD_CENTS'
}

/** bounding box definitions calculated by Geometry */
export type Dimensions = {
  readonly __typename?: 'Dimensions';
  readonly x: Scalars['Float'];
  readonly y: Scalars['Float'];
  readonly z: Scalars['Float'];
};

export type GetQuotesArgs = {
  /** Only return quotes that DO NOT have the given InstantQuoteStatus(es). */
  readonly excludeStatuses: Maybe<ReadonlyArray<Maybe<InstantQuoteStatus>>>;
  /** Only return quotes that have the given InstantQuoteStatus(es). */
  readonly includeStatuses: Maybe<ReadonlyArray<Maybe<InstantQuoteStatus>>>;
  /** The unique id of the quote owner */
  readonly ownerId: Maybe<Scalars['String']>;
  /** The page number returned - used for pagination. Defaults to 1. */
  readonly page: Maybe<Scalars['Int']>;
  /** The number of quotes returned per page. Defaults to 2. */
  readonly pageSize: Maybe<Scalars['Int']>;
};

export type GetUserPartsArgs = {
  /**
   * The unique id of the quote owner. If not passed, getUserParts will
   * return the current user's parts.
   */
  readonly ownerId: Maybe<Scalars['String']>;
  /** The page number returned - used for pagination. */
  readonly page: Maybe<Scalars['Int']>;
  /** The number of quotes returned per page. */
  readonly pageSize: Maybe<Scalars['Int']>;
  /** The user-supplied search term to filter the parts query. */
  readonly searchTerm: Maybe<Scalars['String']>;
};


export type InstantQuote = {
  readonly __typename?: 'InstantQuote';
  /** The unique InstantQuote id. */
  readonly id: Scalars['String'];
  /** The datetime the quote was last updated, if any. */
  readonly lastUpdated: Scalars['GraphQLDateTime'];
  /**
   * The number of business days as an integer.
   * If parts have not been quoted, this will be null.
   */
  readonly leadTime: Maybe<Scalars['Int']>;
  /** The unique id of the quote owner */
  readonly ownerId: Maybe<Scalars['String']>;
  /** The list of QuotedParts for an InstantQuote. */
  readonly quotedParts: ReadonlyArray<Maybe<QuotedPart>>;
  /** The most recent quote status. */
  readonly status: InstantQuoteStatus;
  /**
   * The MonetaryValue for the fabrication and finishing of the parts.
   * If parts have not been quoted, this will be null.
   */
  readonly subtotal: Maybe<MonetaryValue>;
  /** Indicates which Tier should be used for this order. */
  readonly tier: Maybe<InstantQuoteTier>;
  /** Status of the Quote in Punchout system (optional, not there for non-PunchOut Quotes) */
  readonly punchOutStatus: Maybe<InstantQuotePunchoutStatus>;
};

export type InstantQuoteFile = {
  readonly __typename?: 'InstantQuoteFile';
  readonly name: Maybe<Scalars['String']>;
  readonly url: Maybe<Scalars['String']>;
};

export enum InstantQuotePunchoutStatus {
  /** The Quote was created through the PunchOut system */
  PunchoutCreated = 'PUNCHOUT_CREATED',
  /** PO has been created and is being submitted / approved through PunchOut system */
  PunchoutRequested = 'PUNCHOUT_REQUESTED',
  /** PO has been reviewed in PunchOut and sent to Xometry for Approval */
  PunchoutReceived = 'PUNCHOUT_RECEIVED',
  /** PO has been recieved, and the Quote has been ordered (or in the process of being Ordered) */
  PunchoutApproved = 'PUNCHOUT_APPROVED',
  /** The PunchOut quote has been changed into a regular Xometry Quote/Order purchasable on our system */
  PunchoutConverted = 'PUNCHOUT_CONVERTED'
}

export enum InstantQuoteStatus {
  /** All parts have been automatically priced. */
  AutoQuoted = 'AUTO_QUOTED',
  /** Staff has finished manual pricing, customer can now checkout. */
  ManualQuoteFinished = 'MANUAL_QUOTE_FINISHED',
  /** Staff has manually requoted, customer can now checkout. */
  Requoted = 'REQUOTED',
  /** Staff is actively adjusting prices, customers cannot view quote or checkout. */
  ManualQuoteInProgress = 'MANUAL_QUOTE_IN_PROGRESS',
  /** Customer has requested manual pricing. */
  ManualQuoteRequested = 'MANUAL_QUOTE_REQUESTED',
  /** Some parts cannot be auto-quoted. */
  ManualQuoteRequired = 'MANUAL_QUOTE_REQUIRED',
  /**
   * Further action is required by the customer before quote can be generated.
   * For example, customers must specify measurement units on .stl files.
   */
  CustomerActionRequired = 'CUSTOMER_ACTION_REQUIRED',
  /** Quote has been ordered. */
  Ordered = 'ORDERED'
}

export enum InstantQuoteTier {
  Economy = 'ECONOMY',
  Expedite = 'EXPEDITE',
  Standard = 'STANDARD'
}

export type MonetaryValue = {
  readonly __typename?: 'MonetaryValue';
  /** The amount in the smallest denomination of the specified currency. */
  readonly amount: Maybe<Scalars['Int']>;
  /** The 4217 ISO currency code and smallest denomination, e.g. US_CENTS. */
  readonly currency: Maybe<Currency>;
};

/** @group Mutations */
export type Mutation = {
  readonly __typename?: 'Mutation';
  readonly createPart: Maybe<CreatePartResponse>;
  readonly createQuote: Maybe<CreateQuoteResponse>;
  readonly sendShareRequest: Maybe<ShareQuoteRequestResponse>;
};


/** @group Mutations */
export type MutationCreatePartArgs = {
  args: Maybe<CreatePartArgs>;
};


/** @group Mutations */
export type MutationCreateQuoteArgs = {
  args: Maybe<CreateQuoteArgs>;
};


/** @group Mutations */
export type MutationSendShareRequestArgs = {
  args: Maybe<ShareRequestArgs>;
};

/** A return type to indicate the success of organization operation(create, update, etc) */
export type Organization = {
  readonly __typename?: 'Organization';
  /** Unique index id of the organization */
  readonly id: Scalars['ID'];
  /** Human readable name of the organization */
  readonly name: Scalars['String'];
  /** Unique randomly generated ID of the organization */
  readonly refId: Scalars['String'];
};

export type OrganizationInvitation = {
  readonly __typename?: 'OrganizationInvitation';
  /** Unique index id of the organization user */
  readonly id: Scalars['ID'];
  /** Status of the invitation */
  readonly status: Scalars['String'];
  /** The organization that owns this invitation */
  readonly organization: Organization;
  /** Return the xometry user data of the recipient */
  readonly userData: Maybe<OrganizationUserData>;
  /** The xometry user data of the sender */
  readonly senderUserData: Maybe<OrganizationUserData>;
  /** The link to accept the organization invitation */
  readonly acceptInviteUrl: Maybe<Scalars['String']>;
  /** The link to decline the organization invitation */
  readonly declineInviteUrl: Maybe<Scalars['String']>;
};

export type OrganizationUserData = {
  readonly __typename?: 'OrganizationUserData';
  /** user id */
  readonly userId: Maybe<Scalars['ID']>;
  /** first name of the  user */
  readonly firstName: Maybe<Scalars['String']>;
  /** last name of the  user */
  readonly lastName: Maybe<Scalars['String']>;
  /** email of the  user */
  readonly email: Maybe<Scalars['String']>;
};

export type Part = {
  readonly __typename?: 'Part';
  /** The unique Part id. */
  readonly id: Scalars['String'];
  /**
   * when we move to federation ...
   * extend type Part @key(fields: "solidModelFileId")
   * in part-file-service or a geometry graphql interface
   */
  readonly geometry: Maybe<PartGeometry>;
  /**
   * when we move to federation ...
   * extend type Part @key(fields: "solidModelFileId")
   * in part-file-service
   *
   * ***
   * A File object that references the CAD or STL file that was uploaded.
   */
  readonly solidModel: Maybe<InstantQuoteFile>;
  /** File object that refers to the preview image. */
  readonly thumbnail: Maybe<InstantQuoteFile>;
};

export type PartGeometry = {
  readonly __typename?: 'PartGeometry';
  /**
   * Bounding box dimensions (x, y, z) in alternate units. For example, if
   * primary units are "mm", altDimensions will be "in" and vice-versa.
   */
  readonly altDimensions: Maybe<Dimensions>;
  /** Alternate dimension units than those specified by the user */
  readonly altUnits: Maybe<UnitOfMeasurement>;
  /** Bounding box dimensions (x, y ,z) in units */
  readonly dimensions: Maybe<Dimensions>;
  /** Dimension units specified by user */
  readonly units: Maybe<UnitOfMeasurement>;
};

/** Root query type. */
export type Query = {
  readonly __typename?: 'Query';
  /** Get the most recent quotes for a user */
  readonly getQuotes: Maybe<ReadonlyArray<Maybe<InstantQuote>>>;
  readonly getUserParts: Maybe<ReadonlyArray<Maybe<Part>>>;
  readonly getUserCarbonOffset: CarbonOffsetResponse;
  /** Fetch all organizations for the given user id */
  readonly getUsersOrganizationsByIdentity: ReadonlyArray<Organization>;
  /** Information for the currently logged in user */
  readonly me: Maybe<User>;
  /**
   * Fetch all invitations for a user based on their email address. Only staff and the user who owns the email
   * address may call this query.
   */
  readonly getOrganizationInvitationsByEmail: ReadonlyArray<OrganizationInvitation>;
};


/** Root query type. */
export type QueryGetQuotesArgs = {
  args: Maybe<GetQuotesArgs>;
};


/** Root query type. */
export type QueryGetUserPartsArgs = {
  args: Maybe<GetUserPartsArgs>;
};


/** Root query type. */
export type QueryGetUserCarbonOffsetArgs = {
  userId: Scalars['String'];
};


/** Root query type. */
export type QueryGetUsersOrganizationsByIdentityArgs = {
  application: Scalars['String'];
  userId: Scalars['String'];
};


/** Root query type. */
export type QueryGetOrganizationInvitationsByEmailArgs = {
  email: Scalars['String'];
};

export type QuotedPart = {
  readonly __typename?: 'QuotedPart';
  /** The unique QuotedPart id. */
  readonly id: Scalars['String'];
  /** The Part object this QuotedPart will reference. */
  readonly part: Maybe<Part>;
};

export type ShareQuoteRequestResponse = {
  readonly __typename?: 'ShareQuoteRequestResponse';
  /** Whether the request succeeded or not */
  readonly status: Scalars['String'];
};

export type ShareRequestArgs = {
  readonly quoteId: Maybe<Scalars['String']>;
  readonly note: Maybe<Scalars['String']>;
};

export enum UnitOfMeasurement {
  In = 'in',
  Mm = 'mm'
}

/** User's information and current status, along with their company record */
export type User = {
  readonly __typename?: 'User';
  /** 5 digit hex string of user id */
  readonly id: Scalars['ID'];
  /** First and last name */
  readonly firstName: Maybe<Scalars['String']>;
  readonly lastName: Maybe<Scalars['String']>;
  readonly emailAddress: Maybe<Scalars['String']>;
};

export const FullOrganizationInvitation = gql`
    fragment FullOrganizationInvitation on OrganizationInvitation {
  id
  status
  userData {
    firstName
    lastName
    email
  }
  senderUserData {
    firstName
    lastName
    email
  }
  acceptInviteUrl
  declineInviteUrl
  organization {
    name
    id
  }
}
    `;
export const GetQuotesQuery = gql`
    query getQuotes($excludeStatuses: [InstantQuoteStatus], $includeStatuses: [InstantQuoteStatus], $ownerId: String, $page: Int, $pageSize: Int) {
  getQuotes(
    args: {excludeStatuses: $excludeStatuses, includeStatuses: $includeStatuses, ownerId: $ownerId, page: $page, pageSize: $pageSize}
  ) {
    id
    lastUpdated
    leadTime
    quotedParts {
      id
      part {
        id
        thumbnail {
          url
        }
        solidModel {
          name
        }
      }
    }
    status
    subtotal {
      amount
      currency
    }
    tier
    punchOutStatus
    ownerId
  }
}
    `;
export const GetLoggedInUserQuery = gql`
    query getLoggedInUser {
  me {
    id
    firstName
    lastName
    emailAddress
  }
}
    `;
export const GetUserPartsQuery = gql`
    query getUserParts($page: Int, $pageSize: Int, $searchTerm: String) {
  getUserParts(args: {page: $page, pageSize: $pageSize, searchTerm: $searchTerm}) {
    id
    geometry {
      units
      dimensions {
        x
        y
        z
      }
      altUnits
      altDimensions {
        x
        y
        z
      }
    }
    solidModel {
      name
    }
    thumbnail {
      url
    }
  }
}
    `;
export const GetUserCarbonOffsetQuery = gql`
    query getUserCarbonOffset($userId: String!) {
  getUserCarbonOffset(userId: $userId) {
    totalUserCo2Pounds
    totalWorldCo2Pounds
  }
}
    `;
export const CreatePartQuery = gql`
    mutation createPart($fileName: String, $md5: String) {
  createPart(args: {fileName: $fileName, md5: $md5}) {
    id
    ownerId
    existingPart
    uploadUrl
  }
}
    `;
export const CreateQuoteQuery = gql`
    mutation createQuote($partIds: [String], $isPunchout: Boolean) {
  createQuote(args: {partIds: $partIds, isPunchout: $isPunchout}) {
    id
  }
}
    `;
export const SendShareRequestQuery = gql`
    mutation sendShareRequest($quoteId: String!, $note: String) {
  sendShareRequest(args: {quoteId: $quoteId, note: $note}) {
    status
  }
}
    `;
export const GetUsersOrganizationsByIdentityQuery = gql`
    query getUsersOrganizationsByIdentity($application: String!, $userId: String!) {
  getUsersOrganizationsByIdentity(application: $application, userId: $userId) {
    id
    name
    refId
  }
}
    `;
export const GetOrganizationInvitationsByEmailQuery = gql`
    query getOrganizationInvitationsByEmail($email: String!) {
  getOrganizationInvitationsByEmail(email: $email) {
    ...FullOrganizationInvitation
  }
}
    ${FullOrganizationInvitation}`;
export type GetQuotesQueryVariables = Exact<{
  excludeStatuses: Maybe<ReadonlyArray<Maybe<InstantQuoteStatus>> | Maybe<InstantQuoteStatus>>;
  includeStatuses: Maybe<ReadonlyArray<Maybe<InstantQuoteStatus>> | Maybe<InstantQuoteStatus>>;
  ownerId: Maybe<Scalars['String']>;
  page: Maybe<Scalars['Int']>;
  pageSize: Maybe<Scalars['Int']>;
}>;


export type GetQuotesQueryResult = (
  { readonly __typename?: 'Query' }
  & { readonly getQuotes: Maybe<ReadonlyArray<Maybe<(
    { readonly __typename?: 'InstantQuote' }
    & Pick<InstantQuote, 'id' | 'lastUpdated' | 'leadTime' | 'status' | 'tier' | 'punchOutStatus' | 'ownerId'>
    & { readonly quotedParts: ReadonlyArray<Maybe<(
      { readonly __typename?: 'QuotedPart' }
      & Pick<QuotedPart, 'id'>
      & { readonly part: Maybe<(
        { readonly __typename?: 'Part' }
        & Pick<Part, 'id'>
        & { readonly thumbnail: Maybe<(
          { readonly __typename?: 'InstantQuoteFile' }
          & Pick<InstantQuoteFile, 'url'>
        )>, readonly solidModel: Maybe<(
          { readonly __typename?: 'InstantQuoteFile' }
          & Pick<InstantQuoteFile, 'name'>
        )> }
      )> }
    )>>, readonly subtotal: Maybe<(
      { readonly __typename?: 'MonetaryValue' }
      & Pick<MonetaryValue, 'amount' | 'currency'>
    )> }
  )>>> }
);

export type GetLoggedInUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLoggedInUserQueryResult = (
  { readonly __typename?: 'Query' }
  & { readonly me: Maybe<(
    { readonly __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'emailAddress'>
  )> }
);

export type GetUserPartsQueryVariables = Exact<{
  page: Maybe<Scalars['Int']>;
  pageSize: Maybe<Scalars['Int']>;
  searchTerm: Maybe<Scalars['String']>;
}>;


export type GetUserPartsQueryResult = (
  { readonly __typename?: 'Query' }
  & { readonly getUserParts: Maybe<ReadonlyArray<Maybe<(
    { readonly __typename?: 'Part' }
    & Pick<Part, 'id'>
    & { readonly geometry: Maybe<(
      { readonly __typename?: 'PartGeometry' }
      & Pick<PartGeometry, 'units' | 'altUnits'>
      & { readonly dimensions: Maybe<(
        { readonly __typename?: 'Dimensions' }
        & Pick<Dimensions, 'x' | 'y' | 'z'>
      )>, readonly altDimensions: Maybe<(
        { readonly __typename?: 'Dimensions' }
        & Pick<Dimensions, 'x' | 'y' | 'z'>
      )> }
    )>, readonly solidModel: Maybe<(
      { readonly __typename?: 'InstantQuoteFile' }
      & Pick<InstantQuoteFile, 'name'>
    )>, readonly thumbnail: Maybe<(
      { readonly __typename?: 'InstantQuoteFile' }
      & Pick<InstantQuoteFile, 'url'>
    )> }
  )>>> }
);

export type GetUserCarbonOffsetQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type GetUserCarbonOffsetQueryResult = (
  { readonly __typename?: 'Query' }
  & { readonly getUserCarbonOffset: (
    { readonly __typename?: 'CarbonOffsetResponse' }
    & Pick<CarbonOffsetResponse, 'totalUserCo2Pounds' | 'totalWorldCo2Pounds'>
  ) }
);

export type CreatePartMutationVariables = Exact<{
  fileName: Maybe<Scalars['String']>;
  md5: Maybe<Scalars['String']>;
}>;


export type CreatePartMutationResult = (
  { readonly __typename?: 'Mutation' }
  & { readonly createPart: Maybe<(
    { readonly __typename?: 'CreatePartResponse' }
    & Pick<CreatePartResponse, 'id' | 'ownerId' | 'existingPart' | 'uploadUrl'>
  )> }
);

export type CreateQuoteMutationVariables = Exact<{
  partIds: Maybe<ReadonlyArray<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  isPunchout: Maybe<Scalars['Boolean']>;
}>;


export type CreateQuoteMutationResult = (
  { readonly __typename?: 'Mutation' }
  & { readonly createQuote: Maybe<(
    { readonly __typename?: 'CreateQuoteResponse' }
    & Pick<CreateQuoteResponse, 'id'>
  )> }
);

export type SendShareRequestMutationVariables = Exact<{
  quoteId: Scalars['String'];
  note: Maybe<Scalars['String']>;
}>;


export type SendShareRequestMutationResult = (
  { readonly __typename?: 'Mutation' }
  & { readonly sendShareRequest: Maybe<(
    { readonly __typename?: 'ShareQuoteRequestResponse' }
    & Pick<ShareQuoteRequestResponse, 'status'>
  )> }
);

export type GetUsersOrganizationsByIdentityQueryVariables = Exact<{
  application: Scalars['String'];
  userId: Scalars['String'];
}>;


export type GetUsersOrganizationsByIdentityQueryResult = (
  { readonly __typename?: 'Query' }
  & { readonly getUsersOrganizationsByIdentity: ReadonlyArray<(
    { readonly __typename?: 'Organization' }
    & Pick<Organization, 'id' | 'name' | 'refId'>
  )> }
);

export type FullOrganizationInvitationFragment = (
  { readonly __typename?: 'OrganizationInvitation' }
  & Pick<OrganizationInvitation, 'id' | 'status' | 'acceptInviteUrl' | 'declineInviteUrl'>
  & { readonly userData: Maybe<(
    { readonly __typename?: 'OrganizationUserData' }
    & Pick<OrganizationUserData, 'firstName' | 'lastName' | 'email'>
  )>, readonly senderUserData: Maybe<(
    { readonly __typename?: 'OrganizationUserData' }
    & Pick<OrganizationUserData, 'firstName' | 'lastName' | 'email'>
  )>, readonly organization: (
    { readonly __typename?: 'Organization' }
    & Pick<Organization, 'name' | 'id'>
  ) }
);

export type GetOrganizationInvitationsByEmailQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type GetOrganizationInvitationsByEmailQueryResult = (
  { readonly __typename?: 'Query' }
  & { readonly getOrganizationInvitationsByEmail: ReadonlyArray<(
    { readonly __typename?: 'OrganizationInvitation' }
    & FullOrganizationInvitationFragment
  )> }
);
